@import "~@/styles/variables/variables.scss";











































































































































































.customer-info-modal {
	width: 19vw;
	padding: 12px 12px 0;
	border-radius: 4px;
	border: 1px solid #f1f1f1;
	height: 100%;
	display: flex;
	flex-direction: column;
	.basic-info {
		border-bottom: 1px solid #f1f1f1;
		padding-bottom: 8px;
		.header {
			display: flex;
			padding: 12px;
			.name {
				margin-left: 12px;
				flex: 1;
				h5 {
					display: flex;
					align-items: center;
					span {
						color: #282c42;
						font-size: 20px;
						font-weight: 600;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					em {
						background: #f2f6ff;
						color: #3f435e;
						font-size: 14px;
						line-height: 21px;
						padding: 2px 8px;
						display: inline-block;
						border-radius: 4px;
						position: relative;
						top: 1px;
						margin-left: 6px;
						flex-shrink: 0;
					}
					img {
						margin-left: auto;
						cursor: pointer;
					}
				}
				p {
					color: #9a9cb0;
					font-size: 14px;
					margin-top: 4px;
				}
			}
		}
	}
	.relation-devices {
		margin-top: 18px;
		overflow-y: scroll;
		flex: 1;
		height: 0;
		h3 {
			position: relative;
			color: #3f435e;
			font-size: 18px;
			font-weight: 600;
			padding-left: 10px;
			&:after {
				content: '';
				position: absolute;
				width: 4px;
				height: 16px;
				background-color: #4a69ff;
				left: 0;
				top: 3px;
			}
		}
		.list {
			margin-top: 18px;
			&-item {
				background-color: #fbfcff;
				border-radius: 6px;
				border: 1px solid #f1f1f1;
				padding: 12px 12px 8px;
				cursor: pointer;
				&.selected {
					background-color: #e8efff;
					border-color: #567ced;
				}
				&:not(:last-child) {
					margin-bottom: 12px;
				}
				.title_n {
					display: flex;
					align-items: center;
					.name {
						color: #4e4e4e;
						font-weight: 500;
						font-size: 16px;
						margin-left: 8px;
					}
					.tag {
						margin-left: auto;
						background-color: #16c972;
						color: #fff;
						font-size: 12px;
						border-radius: 12px;
						padding: 3px 8px 4px 8px;
						flex-shrink: 0;
						&.offline {
							background-color: #aaabbd;
						}
					}
				}
				.label-form {
					margin-top: 12px;
				}
				.alarm-list {
					margin-top: 8px;
					&-item {
						margin-bottom: 6px;
						background-color: #fff1f1;
						padding: 12px;
						border-radius: 4px;
						.desc {
							display: flex;
							p {
								color: #ed5672;
								font-size: 14px;
								font-weight: 700;
								line-height: 24px;
								&.status {
									margin-left: auto;
								}
							}
						}
						.time {
							color: #f592a4;
							font-size: 12px;
							margin-top: 4px;
						}
					}
				}
			}
		}
	}
	.label-form {
		padding: 0 8px;
		&-item {
			display: flex;
			padding: 6px 0;
			&__title {
				color: #9a9cb0;
			}
			&__value {
				color: #5f627d;
				width: 0;
				flex: 1;
				text-align: right;
				line-height: 1.2;
			}
		}
	}
}
