@import "~@/styles/variables/variables.scss";

















































































































































































































































@import '../run-manage/runLayout.scss';
.serach-ops {
	margin-bottom: 14px;
}
.table-show {
	display: flex;
	.gc-table {
		width: 0;
		flex: 1;
	}
	.customer-info {
		margin-left: 12px;
	}
}
